import { getCampaignsStat } from 'api/endpoints/campaigns';
import { useCampaignsQueryParams } from 'queryParamState/campaigns';
import { useQuery } from 'react-query';
import { ApiCampaigns } from 'types/restApi/campaigns';

export const useReportRequest = (): {
  isFetched: boolean;
  isFetching: boolean;
  data: ApiCampaigns;
} => {
  const [campaignsQueryParams] = useCampaignsQueryParams();

  const {
    isFetched,
    isFetching,
    data = { data: [] },
  } = useQuery(
    [getCampaignsStat.name, campaignsQueryParams],
    () =>
      getCampaignsStat({
        idOrder: campaignsQueryParams.idOrder as number,
        startDate: campaignsQueryParams.startDate,
        endDate: campaignsQueryParams.endDate,
      }),
    {
      enabled: !!campaignsQueryParams.idOrder,
    }
  );

  return { isFetched, isFetching, data };
};
