import { Box, Toolbar } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AppNavBar } from './AppNavBar';

export const AppLayout: FC = () => (
  <>
    <AppNavBar />
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Toolbar variant="dense" />
      <Box sx={{ height: 50 }} />
      <Outlet />
      <Box sx={{ height: 100 }} />
    </Box>
  </>
);
