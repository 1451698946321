import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { ApiCampaignEntity } from 'types/restApi/campaigns';

export const useTableColumns = (): Column<ApiCampaignEntity>[] => {
  return useMemo<Column<ApiCampaignEntity>[]>(
    () => [
      {
        Header: 'Дата',
        accessor: 'date',
        Cell: ({ value }) => format(new Date(value), 'P', { locale: ruLocale }),
      },
      {
        Header: 'Оператор',
        accessor: 'operator',
      },
      {
        Header: 'Запросов',
        accessor: 'requests',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Всего запросов',
        accessor: 'totalRequests',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Показов',
        accessor: 'impressions',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Всего показов',
        accessor: 'totalImpressions',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Уников',
        accessor: 'unique',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Всего уников',
        accessor: 'totalUnique',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Первых квартилей',
        accessor: '1q',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Вторых квартилей',
        accessor: '2q',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Третьих квартилей',
        accessor: '3q',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
      {
        Header: 'Четвертых квартилей',
        accessor: '4q',
        Cell: ({ value }) => value.toLocaleString('ru'),
      },
    ],
    []
  );
};
