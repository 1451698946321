import { DevTool } from '@hookform/devtools';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { loginUser } from 'api/endpoints/login';
import { REQUIRED_FIELD_MSG } from 'constants/misc';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAccessToken } from 'shared/accessToken';
import { useToggleState } from 'shared/hooks/useToggleState';
import { toggleUserLoggedIn } from 'state/appOptions';
import { LoginResponse } from 'types/restApi/login';

type LoginFormValues = {
  username: string;
  passwd: string;
};

export const Login: FC = () => {
  const [showPassword, setShowPassword] = useToggleState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword();
  };

  const { handleSubmit, reset, control } = useForm<LoginFormValues>({
    defaultValues: {
      username: process.env.REACT_APP_LOGIN_USER,
      passwd: process.env.REACT_APP_LOGIN_PASSWORD,
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation<
    LoginResponse,
    unknown,
    LoginFormValues
  >((loginData) => loginUser(loginData), {
    onSuccess: (data) => {
      setAccessToken({
        accessToken: data.token,
      });
      dispatch(toggleUserLoggedIn());

      const from = location.state?.from?.pathname
        ? `${location.state.from.pathname}${location.state.from.search}`
        : '/report';
      navigate(from, { replace: true });
    },
  });

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    mutate(data);
    reset();
  };

  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center',
        flexGrow: 1,
      }}
    >
      <Paper sx={{ p: 4 }}>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            mb: 4,
          }}
        >
          <Avatar sx={{ bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Grid
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          container
          rowSpacing={2}
          maxWidth={300}
        >
          <Grid item xs={12}>
            <Controller
              name="username"
              control={control}
              rules={{
                required: REQUIRED_FIELD_MSG,
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Пользователь *"
                  size="small"
                  value={value}
                  onChange={onChange}
                  disabled={isLoading}
                  fullWidth
                  helperText=" "
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="passwd"
              control={control}
              rules={{
                required: REQUIRED_FIELD_MSG,
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Пароль *"
                  size="small"
                  value={value}
                  onChange={onChange}
                  disabled={isLoading}
                  fullWidth
                  helperText=" "
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
            >
              Войти
            </Button>
          </Grid>
          <DevTool control={control} placement="top-right" />
        </Grid>
      </Paper>
    </Box>
  );
};
