import { UseFormGetValues } from 'react-hook-form';

type ValidateDateIntersection = (params: {
  startDateName?: string;
  endDateName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>;
  errorMessage: string;
}) => string | boolean;

export const validateDateIntersection: ValidateDateIntersection = ({
  startDateName = 'startDate',
  endDateName = 'endDate',
  getValues,
  errorMessage,
}) => {
  const [currentStartDate, currentEndDate] = getValues([
    startDateName,
    endDateName,
  ]);

  if (!currentStartDate || !currentEndDate) {
    return true;
  }

  return new Date(currentStartDate).toISOString() >
    new Date(currentEndDate).toISOString()
    ? errorMessage
    : true;
};
