import {
  ApiCampaignIds,
  apiCampaignIds,
  apiCampaigns,
  ApiCampaigns,
  ApiCampaignsQueryParams,
} from 'types/restApi/campaigns';
import { CancellablePromise, requestCreator } from '../requestCreator';

type GetCampaignsStat = {
  (queryParams: ApiCampaignsQueryParams): CancellablePromise<ApiCampaigns>;
};

export const getCampaignsStat: GetCampaignsStat = (queryParams) =>
  requestCreator({
    options: { url: '/statistics', method: 'GET', params: queryParams },
    decoder: apiCampaigns,
  });

type GetCampaignIds = {
  (): CancellablePromise<ApiCampaignIds>;
};

export const getCampaignIds: GetCampaignIds = () =>
  requestCreator({
    options: { url: '/orders', method: 'GET' },
    decoder: apiCampaignIds,
  });

type GetCampaignsStatFile = {
  (queryParams: ApiCampaignsQueryParams): CancellablePromise<unknown>;
};

export const getCampaignsStatFile: GetCampaignsStatFile = (queryParams) =>
  requestCreator({
    options: {
      url: '/excel',
      method: 'GET',
      params: queryParams,
      responseType: 'blob',
    },
  });
