import { ThemeProvider } from '@mui/material/styles';
import { IS_DEV } from 'constants/misc';
import { FC } from 'react';
import { useAppSelector } from 'shared/useAppSelector';
import { createCustomTheme } from './themeOptions';

export const WrappedThemeProvider: FC = ({ children }) => {
  const isDarkMode = useAppSelector((state) => state.appOptions.isDarkMode);

  const createdTheme = createCustomTheme(isDarkMode);

  if (IS_DEV) {
    // eslint-disable-next-line no-console
    console.log('theme-->', createdTheme);
  }

  return <ThemeProvider theme={createdTheme}>{children}</ThemeProvider>;
};
