import { TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  columnNumber: number;
};

export const TableRowDataNotAvailable: FC<Props> = ({ columnNumber }) => (
  <TableRow>
    <TableCell colSpan={columnNumber}>
      <Typography textAlign="center" variant="body2" component="div">
        Данные отсутствуют
      </Typography>
    </TableCell>
  </TableRow>
);
