import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { TableInstance } from 'react-table';
import { TableRowDataNotAvailable } from 'shared/TableRowDataNotAvailable';
import { ApiCampaignEntity } from 'types/restApi/campaigns';

type Props = {
  tableInstance: TableInstance<ApiCampaignEntity>;
};

export const ReportTable: FC<Props> = ({ tableInstance }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
  } = tableInstance;

  return (
    <TableContainer component={Paper}>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  sx={{
                    // 150 задано по умолчанию в react-table
                    width: column.width !== 150 ? column.width : undefined,
                  }}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <TableCell
                    {...cell.getCellProps()}
                    sx={{
                      width:
                        cell.column.width !== 150
                          ? cell.column.width
                          : undefined,
                    }}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          {rows.length === 0 && (
            <TableRowDataNotAvailable columnNumber={visibleColumns.length} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
