import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'shared/useAppSelector';

export const ProtectedRoute: FC = ({ children }) => {
  const isUserLoggedIn = useAppSelector(
    (state) => state.appOptions.isUserLoggedIn
  );
  const location = useLocation();

  if (!isUserLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};
