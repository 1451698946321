import { combineReducers } from '@reduxjs/toolkit';
import { apiAlertSnackbar } from './apiAlertSnackbar';
import { appOptions } from './appOptions';

export const rootReducer = combineReducers({
  appOptions,
  apiAlertSnackbar,
});

export type RootState = ReturnType<typeof rootReducer>;
