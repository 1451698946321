import { LoginResponse } from 'types/restApi/login';

type AccessToken = LoginResponse['token'] | null;

let accessToken: AccessToken = null;

type TokenData = {
  accessToken: AccessToken;
};

export const setAccessToken = (tokenData: TokenData): void => {
  accessToken = tokenData.accessToken;
};

export const getAccessToken = (): TokenData => ({
  accessToken,
});

export const resetAccessToken = (): void => {
  accessToken = null;
};
