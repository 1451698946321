import { AxiosError } from 'axios';

export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).isAxiosError !== undefined;

export const isFormError = (
  error: unknown
): error is { response: { data: Record<string, string> } } =>
  isAxiosError(error) &&
  error.response?.status === 422 &&
  typeof error.response?.data === 'object';

export const isForbiddenError = (
  error: unknown
): error is { response: { data: Record<string, string> } } =>
  isAxiosError(error) &&
  error.response?.status === 403 &&
  typeof error.response?.data === 'object' &&
  (error.response.data as { detail: string }).detail !== undefined;
