import { Alert, Snackbar } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'shared/useAppSelector';
import { closeApiAlertSnackbar } from 'state/apiAlertSnackbar';

export const ApiAlertSnackbar: FC = () => {
  const { id, alertType, open, message } = useAppSelector(
    (state) => state.apiAlertSnackbar
  );
  const dispatch = useDispatch();

  return (
    <Snackbar
      key={id}
      open={open}
      autoHideDuration={alertType === 'error' ? null : 5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => {
        dispatch(closeApiAlertSnackbar());
      }}
      sx={{
        top: {
          sm: 74,
        },
      }}
    >
      <Alert
        onClose={() => {
          dispatch(closeApiAlertSnackbar());
        }}
        severity={alertType}
        variant="filled"
        sx={{
          maxWidth: 400,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
