import { IS_DEV } from 'constants/misc';
import { showApiAlertSnackbar } from 'state/apiAlertSnackbar';
import { toggleUserLoggedIn } from 'state/appOptions';
import { store } from 'store';
import { isAxiosError } from 'types/typeGuards';
import { resetAccessToken } from './accessToken';

const isErrorDetail = (
  error: unknown
): error is { response: { data: { detail: string } } } =>
  isAxiosError(error) &&
  typeof error.response?.data === 'object' &&
  (error.response.data as { detail: string }).detail !== undefined;

export const handleApiError = (error: unknown): void => {
  // Ошибки в полях форм обрабатываются в формах
  if (isAxiosError(error) && error.response?.status === 422) {
    store.dispatch(
      showApiAlertSnackbar({
        alertType: 'error',
        message: 'Неверный формат данных!',
      })
    );
    return;
  }

  if (isAxiosError(error) && error.response?.status === 404) {
    store.dispatch(
      showApiAlertSnackbar({
        alertType: 'error',
        message: 'Данные не найдены.',
      })
    );

    return;
  }

  if (isAxiosError(error) && error.response?.status === 403) {
    store.dispatch(toggleUserLoggedIn());
    resetAccessToken();

    return;
  }

  if (isErrorDetail(error)) {
    store.dispatch(
      showApiAlertSnackbar({
        alertType: 'error',
        message: error.response.data.detail,
      })
    );

    return;
  }

  if (error instanceof Error && error.message) {
    store.dispatch(
      showApiAlertSnackbar({
        alertType: 'error',
        message: error.message || 'Обнаружены неизвестные ошибки!',
      })
    );

    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('error-->', error);
    }
  }
};
