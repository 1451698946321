import { configureStore } from '@reduxjs/toolkit';
import { IS_DEV } from 'constants/misc';
import { rootReducer } from 'state/rootReducer';
import { saveAppOptionMiddleware } from './saveAppOptionMiddleware';

export const store = configureStore({
  reducer: rootReducer,
  devTools: IS_DEV,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    saveAppOptionMiddleware,
  ],
});
