/* eslint-disable unicorn/prefer-regexp-test */
import { Middleware } from '@reduxjs/toolkit';
import {
  persistAppOptions,
  toggleDarkMode,
  toggleUserLoggedIn,
} from 'state/appOptions';
import { RootState } from 'state/rootReducer';

export const saveAppOptionMiddleware: Middleware<
  Record<string, unknown>,
  RootState
> =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (toggleUserLoggedIn.match(action)) {
      const currentAppOptionsState = getState().appOptions;

      persistAppOptions.set({
        ...currentAppOptionsState,
        isUserLoggedIn: !currentAppOptionsState.isUserLoggedIn,
      });
    }

    if (toggleDarkMode.match(action)) {
      const currentAppOptionsState = getState().appOptions;

      persistAppOptions.set({
        ...currentAppOptionsState,
        isDarkMode: !currentAppOptionsState.isDarkMode,
      });
    }

    return next(action);
  };
