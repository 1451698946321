import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { resetAccessToken } from 'shared/accessToken';
import { useAppSelector } from 'shared/useAppSelector';
import { toggleDarkMode, toggleUserLoggedIn } from 'state/appOptions';

export const AppNavBar: FC = () => {
  const dispatch = useDispatch();
  const isDarkMode = useAppSelector((state) => state.appOptions.isDarkMode);
  const isUserLoggedIn = useAppSelector(
    (state) => state.appOptions.isUserLoggedIn
  );

  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <Box flexGrow={1} />
        <IconButton onClick={() => dispatch(toggleDarkMode())} color="inherit">
          {isDarkMode ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
        </IconButton>
        {isUserLoggedIn && (
          <IconButton
            onClick={() => {
              resetAccessToken();
              dispatch(toggleUserLoggedIn());
            }}
            color="inherit"
          >
            <LogoutRoundedIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};
