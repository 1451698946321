import { DevTool } from '@hookform/devtools';
import { DatePicker } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import { useCampaignsQueryParams } from 'queryParamState/campaigns';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { validateDateIntersection } from 'shared/reactHookForm/validateDateIntersection';
import { ApiCampaignsQueryParams } from 'types/restApi/campaigns';
import { CampaignField } from './CampaignField';
import { useFileReportRequest } from './useFileReportRequest';

export type FormValues = {
  idOrder: number | null;
  startDate: string | null;
  endDate: string | null;
};

export const ReportToolbar: FC = () => {
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      idOrder: null,
      startDate: null,
      endDate: null,
    },
  });

  const [campaignsQueryParams, setcampaignsQueryParams] =
    useCampaignsQueryParams();

  useEffect(() => {
    if (campaignsQueryParams.startDate) {
      setValue('startDate', campaignsQueryParams.startDate, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (campaignsQueryParams.endDate) {
      setValue('endDate', campaignsQueryParams.endDate, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isFileLoading, mutate } = useFileReportRequest();

  const onSubmitFileReport: SubmitHandler<{
    idOrder: number;
    startDate: string | null;
    endDate: string | null;
  }> = (data) => {
    const queryParams: ApiCampaignsQueryParams = {
      idOrder: data.idOrder,
    };

    if (data.startDate) {
      queryParams.startDate = format(new Date(data.startDate), 'yyyy-MM-dd', {
        locale: ruLocale,
      });
    }

    if (data.endDate) {
      queryParams.endDate = format(new Date(data.endDate), 'yyyy-MM-dd', {
        locale: ruLocale,
      });
    }

    mutate(queryParams);
  };

  const onSubmitWebReport: SubmitHandler<FormValues> = (data) => {
    const queryParams: Partial<ApiCampaignsQueryParams> = {};

    if (data.idOrder) queryParams.idOrder = data.idOrder;

    if (data.startDate) {
      queryParams.startDate = format(new Date(data.startDate), 'yyyy-MM-dd', {
        locale: ruLocale,
      });
    }

    if (data.endDate) {
      queryParams.endDate = format(new Date(data.endDate), 'yyyy-MM-dd', {
        locale: ruLocale,
      });
    }

    setcampaignsQueryParams({
      idOrder: queryParams.idOrder,
      startDate: queryParams.startDate || undefined,
      endDate: queryParams.endDate || undefined,
    });
  };

  return (
    <Grid component="form" container columnSpacing={2}>
      <Grid item xs={4}>
        <CampaignField
          control={control}
          setValue={setValue}
          errorMsg={formErrors.idOrder?.message}
          campaignId={campaignsQueryParams.idOrder}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="startDate"
          control={control}
          rules={{
            validate: () =>
              validateDateIntersection({
                getValues,
                errorMessage: 'Должно быть раньше конца кампании.',
              }),
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label="Начало периода"
              disableMaskedInput
              value={value}
              onChange={(...args) => {
                onChange(...args);
                trigger(['startDate', 'endDate']);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  error={!!formErrors.startDate?.message}
                  helperText={formErrors.startDate?.message || ' '}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="endDate"
          control={control}
          rules={{
            validate: () =>
              validateDateIntersection({
                getValues,
                errorMessage: 'Должно быть позже начала кампании.',
              }),
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label="Конец периода"
              disableMaskedInput
              value={value}
              onChange={(...args) => {
                onChange(...args);
                trigger(['startDate', 'endDate']);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  error={!!formErrors.endDate?.message}
                  helperText={formErrors.endDate?.message || ' '}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Stack direction="row" spacing={2}>
          <Button
            onClick={handleSubmit(onSubmitFileReport)}
            variant="outlined"
            disabled={isFileLoading}
          >
            {isFileLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              'Сохранить xls'
            )}
          </Button>
          <Button onClick={handleSubmit(onSubmitWebReport)} variant="contained">
            Сформировать
          </Button>
        </Stack>
      </Grid>
      <DevTool control={control} placement="top-right" />
    </Grid>
  );
};
