import { getCampaignsStatFile } from 'api/endpoints/campaigns';
import { UseMutateFunction, useMutation } from 'react-query';
import { ApiCampaignsQueryParams } from 'types/restApi/campaigns';

export const useFileReportRequest = (): {
  isFileLoading: boolean;
  mutate: UseMutateFunction<unknown, unknown, ApiCampaignsQueryParams>;
} => {
  const { mutate, isLoading } = useMutation<
    unknown,
    unknown,
    ApiCampaignsQueryParams
  >((queryParams) => getCampaignsStatFile(queryParams), {
    onSuccess: (data, { idOrder }) => {
      const a = document.createElement('a');
      const objectUrl = window.URL.createObjectURL(data as Blob);
      a.href = objectUrl;
      a.download = `${idOrder}.xlsx`;
      a.style.display = 'none';
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(objectUrl);
    },
  });

  return { isFileLoading: isLoading, mutate };
};
