import { AlertProps } from '@mui/material';
import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';

type ApiAlertSnackbarSlice = {
  id: string;
  alertType: AlertProps['severity'];
  open: boolean;
  message: string;
};

const initialState: ApiAlertSnackbarSlice = {
  id: '',
  alertType: undefined,
  open: false,
  message: '',
};

const apiAlertSnackbarSlice = createSlice({
  name: 'apiAlertSnackbar',
  initialState,
  reducers: {
    showApiAlertSnackbar: {
      reducer: (
        draft,
        {
          payload,
        }: PayloadAction<
          Pick<ApiAlertSnackbarSlice, 'id' | 'alertType' | 'message'>
        >
      ) => {
        draft.open = true;
        draft.id = payload.id;
        draft.alertType = payload.alertType;
        draft.message = payload.message;
      },
      prepare: (
        payload: Pick<ApiAlertSnackbarSlice, 'alertType' | 'message'>
      ) => {
        const id = nanoid();
        return { payload: { ...payload, id } };
      },
    },

    closeApiAlertSnackbar() {
      return initialState;
    },
  },
});

export const { showApiAlertSnackbar, closeApiAlertSnackbar } =
  apiAlertSnackbarSlice.actions;

export const apiAlertSnackbar = apiAlertSnackbarSlice.reducer;
