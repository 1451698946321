import { createSlice } from '@reduxjs/toolkit';
import { APP_OPTS_LOC_STOR_KEY } from 'constants/misc';

type InitialState = {
  isUserLoggedIn: boolean;
  isDarkMode: boolean;
};

const initialState: InitialState = {
  isDarkMode: false,
  isUserLoggedIn: false,
};

export const persistAppOptions = {
  get: (): InitialState => {
    const rawLocalStorage = window.localStorage.getItem(APP_OPTS_LOC_STOR_KEY);

    if (rawLocalStorage !== null) {
      const parsedLocalStorage = JSON.parse(rawLocalStorage);

      return {
        isDarkMode: parsedLocalStorage.isDarkMode,
        isUserLoggedIn: initialState.isUserLoggedIn,
      };
    }

    return initialState;
  },

  set: (value: InitialState): void => {
    window.localStorage.setItem(
      APP_OPTS_LOC_STOR_KEY,
      JSON.stringify({
        isDarkMode: value.isDarkMode,
      })
    );
  },
};

const appOptionsSlice = createSlice({
  name: APP_OPTS_LOC_STOR_KEY,
  initialState: persistAppOptions.get(),
  reducers: {
    toggleDarkMode(draft) {
      draft.isDarkMode = !draft.isDarkMode;
    },
    toggleUserLoggedIn(draft) {
      draft.isUserLoggedIn = !draft.isUserLoggedIn;
    },
  },
});

export const { toggleUserLoggedIn, toggleDarkMode } = appOptionsSlice.actions;

export const appOptions = appOptionsSlice.reducer;
