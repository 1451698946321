import axios from 'axios';
import queryString from 'query-string';
import { getAccessToken } from 'shared/accessToken';

export const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  timeout: 1 * 60 * 1000, // 1 мин
  paramsSerializer: (params) => queryString.stringify(params),
});

apiAxios.interceptors.request.use(async (config) => {
  const { accessToken } = getAccessToken();

  if (accessToken) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${accessToken}`,
    });
  }

  return config;
});
