import * as t from 'io-ts';

const apiCampaignEntity = t.type({
  id: t.number,
  idOrder: t.number,
  date: t.string,
  idOperator: t.number,
  operator: t.string,
  requests: t.number,
  impressions: t.number,
  unique: t.number,
  '1q': t.number,
  '2q': t.number,
  '3q': t.number,
  '4q': t.number,
  totalRequests: t.number,
  totalImpressions: t.number,
  totalUnique: t.number,
  timeUpdated: t.string,
});

export const apiCampaigns = t.type({
  data: t.array(apiCampaignEntity),
});

export type ApiCampaignEntity = t.TypeOf<typeof apiCampaignEntity>;

export type ApiCampaigns = t.TypeOf<typeof apiCampaigns>;

export type ApiCampaignsQueryParams = {
  idOrder: number;
  startDate?: string;
  endDate?: string;
};

export const apiCampaignIds = t.type({ orders: t.array(t.number) });

export type ApiCampaignIds = t.TypeOf<typeof apiCampaignIds>;
