import { useCallback, useState } from 'react';

type ToggleState<T> = (initialState?: T) => [T, () => void];

export const useToggleState: ToggleState<boolean> = (initialState = false) => {
  const [state, setState] = useState<boolean>(initialState);
  const toggleState = useCallback(
    () => setState((prevState) => !prevState),
    []
  );

  return [state, toggleState];
};
