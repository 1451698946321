import type {} from '@mui/lab/themeAugmentation';
import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { ruRU } from '@mui/material/locale';

const createThemeOptsWithoutComponents = (isDark: boolean): ThemeOptions => ({
  palette: {
    mode: isDark ? 'dark' : 'light',
  },
});

const createThemeOptions = (
  isDark: boolean,
  prevTheme: Theme
): ThemeOptions => ({
  components: {
    MuiTableBody: {
      styleOverrides: {
        root: {
          'tr:nth-of-type(odd) td': {
            backgroundColor: prevTheme.palette.action.hover,
          },
        },
      },
    },
  },
});

export const createCustomTheme = (isDark: boolean): ThemeOptions => {
  const baseThemeOptions = createThemeOptsWithoutComponents(isDark);
  const prevTheme = createTheme(baseThemeOptions);

  const finalTheme = createThemeOptions(isDark, prevTheme);

  return createTheme({ ...baseThemeOptions, ...finalTheme }, ruRU);
};
