import { AppLayout } from 'AppLayout';
import { Login } from 'Login';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Report } from 'Report';
import { ProtectedRoute } from 'shared/ProtectedRoute';
import { PageNotFound } from './PageNotFound';

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={<Navigate to="/report" replace />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/report"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <PageNotFound />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
