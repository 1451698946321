import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Alert,
  AlertTitle,
  Collapse,
  Container,
  Divider,
  Fade,
  IconButton,
  Paper,
} from '@mui/material';
import { IS_DEV } from 'constants/misc';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  techInfoOpen: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      techInfoOpen: false,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, techInfoOpen: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    if (IS_DEV) {
      /* eslint-disable no-console */
      console.log(`error: ${error}`);
      console.log(`errorInfo: ${JSON.stringify(errorInfo)}`);
      console.log(`componentStack: ${errorInfo.componentStack}`);
      /* eslint-enable no-console */
    }
  }

  public render(): ReactNode {
    const { hasError, error, techInfoOpen } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Fade in>
          <Container
            maxWidth="xs"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Paper>
              <Alert severity="error">
                <AlertTitle>Произошла ошибка!</AlertTitle>
                Попробуйте обновить страницу.
                <Divider sx={{ my: 2 }} />
                Техническая информация
                <IconButton
                  color="inherit"
                  onClick={() =>
                    this.setState((prevState) => ({
                      techInfoOpen: !prevState.techInfoOpen,
                    }))
                  }
                  size="large"
                >
                  <ExpandMoreRoundedIcon
                    sx={{
                      transform: techInfoOpen ? 'initial' : 'rotate(-90deg)',
                      transition: 'transform 0.15s ease',
                    }}
                  />
                </IconButton>
                <Collapse in={techInfoOpen} mountOnEnter unmountOnExit>
                  {String(error)}
                </Collapse>
              </Alert>
            </Paper>
          </Container>
        </Fade>
      );
    }

    return children;
  }
}
