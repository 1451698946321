import { FC, useMemo } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

// TODO временное решение, пока не обновится use-query-params
// с поддержкой react-router v6
export const RouteAdapter: FC = ({ children }) => {
  const navigate = useNavigate();
  const reactRouterLocation = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(
          { ...location, pathname: reactRouterLocation.pathname },
          { replace: true, state: location.state }
        );
      },
      push(location: Location) {
        navigate(
          { ...location, pathname: reactRouterLocation.pathname },
          { replace: false, state: location.state }
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  // https://github.com/pbeshai/use-query-params/issues/196
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return children({ history: adaptedHistory, reactRouterLocation });
};
