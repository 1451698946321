import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { getCampaignIds } from 'api/endpoints/campaigns';
import { REQUIRED_FIELD_MSG } from 'constants/misc';
import { FC, useEffect } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useQuery } from 'react-query';
import type { FormValues } from './ReportToolbar';

type Props = {
  control: Control<FormValues>;
  errorMsg?: string;
  setValue: UseFormSetValue<FormValues>;
  campaignId?: number;
};

export const CampaignField: FC<Props> = ({
  control,
  errorMsg,
  setValue,
  campaignId,
}) => {
  const { data = { orders: [] }, isFetching } = useQuery(
    [getCampaignIds.name],
    () => getCampaignIds()
  );

  useEffect(() => {
    if (campaignId && data.orders.length > 0) {
      const selectedId = data.orders.find((id) => id === campaignId);

      if (selectedId) {
        setValue('idOrder', selectedId, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, data]);

  return (
    <Controller
      name="idOrder"
      control={control}
      rules={{
        required: {
          value: true,
          message: REQUIRED_FIELD_MSG,
        },
      }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          selectOnFocus={false}
          loading={isFetching}
          options={data.orders}
          getOptionLabel={(label) => String(label)}
          onChange={(ev, selectedValue) => onChange(selectedValue)}
          value={value}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Кампания *"
              fullWidth
              error={!!errorMsg}
              helperText={errorMsg || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isFetching ? <CircularProgress size={24} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
