import { Box, Container, Typography } from '@mui/material';
import { useCampaignsQueryParams } from 'queryParamState/campaigns';
import { FC } from 'react';
import { useTable } from 'react-table';
import { OverlayLoadingSpinner } from 'shared/OverlayLoadingSpinner';
import { ApiCampaignEntity } from 'types/restApi/campaigns';
import { ReportTable } from './ReportTable';
import { ReportToolbar } from './ReportToolbar';
import { useReportRequest } from './useReportRequest';
import { useTableColumns } from './useTableColumns';

export const Report: FC = () => {
  const { isFetched, isFetching, data = { data: [] } } = useReportRequest();

  const columns = useTableColumns();

  const tableInstance = useTable<ApiCampaignEntity>(
    // Все передаваемые объекты должны быть мемоизированы
    {
      columns,
      data: data.data,
    }
  );

  const [campaignsQueryParams] = useCampaignsQueryParams();

  return (
    <Container component="main" maxWidth="xl">
      <OverlayLoadingSpinner isLoading={isFetching}>
        <Box sx={{ mb: 5 }}>
          <ReportToolbar />
        </Box>
        {isFetched && (
          <>
            <Typography
              component="h1"
              variant="h3"
              textAlign="center"
              gutterBottom
            >
              {`Отчет по кампании ${campaignsQueryParams.idOrder}`}
            </Typography>
            <ReportTable tableInstance={tableInstance} />
          </>
        )}
      </OverlayLoadingSpinner>
    </Container>
  );
};
