import * as t from 'io-ts';

export const loginResponse = t.type({
  token: t.string,
});

export type LoginResponse = t.TypeOf<typeof loginResponse>;

export type LoginRequest = {
  username: string;
  passwd: string;
};
