import { ApiCampaignsQueryParams } from 'types/restApi/campaigns';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { SetQueryParams } from './comonTypes';

type CampaignsQueryParams = {
  idOrder?: ApiCampaignsQueryParams['idOrder'];
  startDate?: ApiCampaignsQueryParams['startDate'];
  endDate?: ApiCampaignsQueryParams['endDate'];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const campaignsQueryParamConfig: Record<keyof CampaignsQueryParams, any> = {
  idOrder: NumberParam,
  startDate: StringParam,
  endDate: StringParam,
};

type UseCampaignsQueryParams = () => [
  queryParams: CampaignsQueryParams,
  setQueryParams: SetQueryParams<CampaignsQueryParams>
];

export const useCampaignsQueryParams: UseCampaignsQueryParams = () => {
  const [queryParams, setQueryParams] = useQueryParams(
    campaignsQueryParamConfig
  );

  return [queryParams, setQueryParams];
};
