/**
 * Примеры по io-ts
 * https://www.azavea.com/blog/2020/10/29/run-time-type-checking-in-typescript-with-io-ts/
 * https://dev.to/gnomff_65/fp-ts-and-beautiful-api-calls-1f55
 * https://www.youtube.com/watch?v=KYyDrlmyxdQ&ab_channel=Futurice
 */
import axios, { AxiosRequestConfig } from 'axios';
import { IS_DEV } from 'constants/misc';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { apiAxios } from './apiAxios';

export interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void;
}

export type RequestCreatorParams<T> = {
  options: AxiosRequestConfig;
  decoder?: t.Decoder<unknown, T>;
};

export const requestCreator = <T>({
  options,
  decoder,
}: RequestCreatorParams<T>): CancellablePromise<T> => {
  const source = axios.CancelToken.source();

  const promise = (async () => {
    const { data } = await apiAxios({
      ...options,
      cancelToken: source.token,
    });

    if (decoder) {
      pipe(
        decoder.decode(data),
        E.getOrElseW((errors) => {
          if (IS_DEV) {
            // eslint-disable-next-line no-console
            console.log('Incorrect API types-->', errors);
            // eslint-disable-next-line no-console
            console.log('Incorrect API data-->', data);
          }

          throw new Error('Сервер вернул неверный формат данных!');
        })
      );
    }

    return data;
  })() as CancellablePromise<T>;

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
