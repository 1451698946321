import {
  LoginRequest,
  loginResponse,
  LoginResponse,
} from 'types/restApi/login';
import { CancellablePromise, requestCreator } from '../requestCreator';

type LoginUser = {
  (data: LoginRequest): CancellablePromise<LoginResponse>;
};

export const loginUser: LoginUser = (data) =>
  requestCreator({
    options: { url: '/auth', method: 'POST', data },
    decoder: loginResponse,
  });
