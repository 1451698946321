import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import { AppRoutes } from 'AppRoutes';
import ruLocale from 'date-fns/locale/ru';
import { FC, StrictMode } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { handleApiError } from 'shared/handleApiError';
import { store } from 'store';
import { QueryParamProvider } from 'use-query-params';
import { ApiAlertSnackbar } from './ApiAlertSnackbar';
import './App.css';
import { ErrorBoundary } from './ErrorBoundary';
import { GlobalStyles } from './GlobalStyles';
import { RouteAdapter } from './RouteAdapter';
import { WrappedThemeProvider } from './WrappedThemeProvider';

const appMutationCache = new MutationCache({
  onError: handleApiError,
});

const appQueryCache = new QueryCache({
  onError: handleApiError,
});

export const appQueryClient = new QueryClient({
  mutationCache: appMutationCache,
  queryCache: appQueryCache,
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 min
      keepPreviousData: true,
    },
  },
});

export const App: FC = () => (
  <StrictMode>
    <Provider store={store}>
      <WrappedThemeProvider>
        <LocalizationProvider dateAdapter={DateAdapter} locale={ruLocale}>
          <CssBaseline />
          <GlobalStyles />
          <ErrorBoundary>
            <ApiAlertSnackbar />
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                <QueryClientProvider client={appQueryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <AppRoutes />
                </QueryClientProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </LocalizationProvider>
      </WrappedThemeProvider>
    </Provider>
  </StrictMode>
);
